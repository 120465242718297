import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "target",
    "loaderTemplate"
  ]

  // To be used with `data-action="ajax:beforeSend->remote#displayLoader"` for example
  displayLoader () {
    if (this.hasLoaderTemplateTarget) {
      this.targetTargets.forEach(target => {
        target.innerHTML = this.loaderTemplateTarget.innerHTML
      })
    }
  }

  replaceOuter (event) {
    const [, , xhr] = event.detail
    this.target.outerHTML = xhr.response
  }

  replaceInner (event) {
    const [, , xhr] = event.detail
    this.target.innerHTML = xhr.response
  }

  append (event) {
    const [, , xhr] = event.detail
    this.target.insertAdjacentHTML('afterend', xhr.response)
  }

  prepend (event) {
    const [, , xhr] = event.detail

    this.elementToReplace.insertAdjacentHTML('beforebegin', xhr.response)
  }

  redirectToLocation (event) {
    const [,, xhr] = event.detail
    const location = xhr.getResponseHeader('location')

    if (location) {
      window.location = location
    }
  }

  get target () {
    return this.hasTargetTarget ? this.targetTarget : this.element
  }
}
