import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'formTypeInput',
    'contactFieldset',
    'displayImageInput',
    'imageFieldset',
    'iconFieldset'
  ]

  connect () {
    this.toggleContactFieldset()
    this.toggleImageOrIconFieldset()
  }

  toggleContactFieldset () {
    if (!this.hasContactFieldsetTarget) return

    const disabled = !(this.formTypeInputTarget.value === "contact")
    this.contactFieldsetTarget.hidden = disabled
    this.contactFieldsetTarget.disabled = disabled
  }

  toggleImageOrIconFieldset () {
    if (this.hasImageFieldsetTarget || this.hasIconFieldsetTarget) {
      const disabled = this.displayImageInputTarget.value !== "true"

      this.imageFieldsetTargets.forEach(input => {
        input.hidden = disabled
        input.disabled = disabled
      })
      this.iconFieldsetTargets.forEach(input => {
        input.hidden = !disabled
        input.disabled = !disabled
      })
    }
  }
}
