import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    label: String,
    labels: Array,
    data: Array,
    options: Object,
    backgrounds: Array
  }

  async connect () {
    await import("chart.js/auto").then(({ default: Chart }) => {
      const data = {
        labels: this.labelsValue,
        datasets: [{
          backgroundColor: this.backgroundsValue,
          data: this.dataValue
        }]
      }

      const config = {
        type: "doughnut",
        data: data,
        responsive: true,
        maintainAspectRatio: true,
        options: {
          plugins: {
            legend: {
              display: false,
              position: "bottom"
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const sumGoals = context.dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                  return context.label + ": " + (context.parsed * 100 / sumGoals).toFixed(2) + '%'
                }
              }
            }
          }
        }
      }

      this.chart = new Chart(this.element, config)
    })
  }

  disconnect () {
    this.chart.destroy()
    this.chart = undefined
  }
}
