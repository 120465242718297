import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "content"]
  static values = {
    buttonClass: { type: String, default: "active" },
    contentClass: { type: String, default: "active" }
  }

  connect () {
    this.buttonTargets[0].classList.add(this.buttonClassValue)
    this.contentTargets[0].classList.add(this.contentClassValue)
  }

  toggleActive (event) {
    event.preventDefault()
    event.stopPropagation()

    this.buttonTargets.forEach((button) => {
      button.classList.toggle(this.buttonClassValue, event.currentTarget.dataset.tabName === button.dataset.tabName)
    })

    this.contentTargets.forEach((content) => {
      content.classList.toggle(this.contentClassValue, event.currentTarget.dataset.tabName === content.dataset.tabName)
    })
  }
}
