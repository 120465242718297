// Affiche le message flash pendant 3 secondes.
//  - content : html, ou texte si type renseigné

export default class {
  // constructeur
  constructor (content, type) {
    // element flash message
    this.$element = document.querySelector('[data-is-flash-message]')

    // récupération content & type
    this.content = content

    if ((type !== null) && (type !== undefined)) {
      this.type = type
    } else {
      this.type = ''
    }

    // initialisation du flash message
    // construction du message + apparition
    this.$element.classList.remove('show')
    this.$element.innerHTML = `
      <div class="alert alert-${this.type}">
        ${this.content}
        <a class="flash-close" data-is-flash-close=""><i class="far fa-times"></i></a>
      </div>
    `

    // disparition automatique du message (au bout de 5 secondes)
    this.$element.classList.add('show')
    setTimeout(() => this.$element.classList.remove('show'), 5000)

    // fermeture du flash message au click sur la croix
    this.$element.querySelector('[data-is-flash-close]').addEventListener('click', () =>
      this.$element.classList.remove('show')
    )
  }
}

const attachAjaxResponseHandler = () => {
  document.addEventListener('ajax:success ajax:error', (event) => {
    const [, , xhr] = event.detail
    const data = JSON.parse(xhr.getResponseHeader('X-Message'))
    if (data) {
      const message = decodeURIComponent(escape(data.message))
      new FlashMessage(message, data.type)
    }
  })
}

if (document.readyState !== 'loading') attachAjaxResponseHandler()
else document.addEventListener('DOMContentLoaded', attachAjaxResponseHandler)
